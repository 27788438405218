<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'cart-menu-row',
  components: {
    BasePlaceholder,
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    iconSize: {
      type: [String, Number],
      default: 'xl',
    },
    iconClass: {
      type: [Object, Array, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div
    class="relative flex items-center justify-between rounded-lg border border-eonx-neutral-200 p-5"
  >
    <div
      v-if="!loading"
      class="mr-6 flex shrink-0 cursor-pointer items-center justify-center rounded-full"
      :class="iconClass"
      @click="$emit('click')"
    >
      <base-icon :size="iconSize" :svg="icon" />
    </div>

    <div v-if="loading" class="w-full px-5 py-[15px]">
      <div class="w-3.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
      <div class="w-1.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
    </div>
    <div v-else class="w-full min-w-0 cursor-pointer" @click="$emit('click')">
      <div class="flex">
        <div class="max-w-full truncate font-bold leading-tight">
          {{ title }}
        </div>
        <div
          v-if="isDefault"
          class="inline-flex items-center text-sm font-semibold leading-6 text-fg-success"
        >
          <base-icon svg="plain/check" size="sm" class="mr-2.5" />
          Default
        </div>
      </div>
      <div class="max-w-full truncate break-words text-sm">
        {{ text }}
      </div>
    </div>

    <div v-if="!loading && $slots.default" class="ml-5 shrink-0">
      <slot />
    </div>
  </div>
</template>
